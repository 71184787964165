.outcomes-wrapper {
  border-radius: 8px;
  background:  #F0F4FF;
  box-shadow: 0px 4px 4px 0px #B9B6E0;
  padding: 24px;
  
  &.recommened{
    background: white;
    &.group {
      box-shadow: 0px 4px 4px 0px #B9B6E0;
    }
  }
}

.doc-name{
  background-color: #0923E6;
  color: white; 
  border-radius: 4px 4px 0px 0px;
}

.outcome-item {
  border-radius: 4px;
  background: #FEFAEB;
}

.outcome-list{
  height: calc(100vh - 500px);
  overflow: auto;
}

.recommened {
  .outcome-list {
    height: calc(100vh - 542px);
  }
}

.outcome-list.emty {
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
}

.more-btn.disabled {
  opacity: 0.2;
  cursor: unset;
}

.more-btn.loading {
  animation: rotation 1s infinite linear;
  cursor: unset;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}