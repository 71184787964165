
@import "../../../variables.scss";

.email-notify {
  background-color: $password-bg;
  width: 100%;
  font-size: 0.8rem;
  box-shadow: 0 0 32px 0px rgba(92, 92, 92, 0.5);

  .btn-link {
    font-size: 0.8rem;
    text-decoration: underline;
  }
}