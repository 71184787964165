.quiz-guide-page-container {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.quiz-guide-page-container .page-content-container {
  flex: 1 1;
  overflow-y: auto;
  padding: 24px 40px 0;
}

.quiz-guide-page-container .glossary-header {
  display: flex;
  justify-content: space-between;
}

.quiz-guide-page-container .glossary-heading {
  font-weight: bold;
}

.quiz-guide-page-container .glossary-body {
  /* padding: 24px 0; */
}

.quiz-guide-page-container .glossary-subheading {
  font-weight: bold;
  text-transform: capitalize;
  /* font-size: 0.9rem; */
}

.quiz-guide-page-container .glossary-alphabet {
  color: #03a8f4;
}

.quiz-guide-page-container .glossary-term-definition {
  font-size: 0.9rem;
}

.quiz-guide-page-container .glossary-definition-pair {
  margin-bottom: 32px;
}

.quiz-guide-page-container .glossary-section {
  margin-bottom: 24px;
  border-bottom: 1px dashed #5f5d73;
}

.quiz-guide-page-container .glossary-img-container {
  width: 100%;
  max-width: 600px;
}

.quiz-guide-page-container .glossary-img {
  width: 100%;
}

.quiz-guide-page-container .glossary-index {
  margin-bottom: 32px;
}

.quiz-guide-page-container .glossary-index-category-container {
  font-size: 0.8rem;
  display: inline-block;
  vertical-align: top;
  margin-right: 40px;
}

.quiz-guide-page-container .glossary-index-catergory-heading {
  margin-top: 0;
}

.hash-link{
  color: #1e4c88;
  text-decoration: underline !important;
}