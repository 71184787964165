.custom-input {
  width: 100%;
  border-radius: 5px;
  background: #fff;
  /* box-shadow: 0px 0px 12px rgba(237, 237, 232, 0.8); */
  border: 1px solid #bec5d9;
  font-family: "Muli";
  font-weight: normal;
  font-size: 0.9rem;
  text-align: left;
  color: #07032b;
  color: var(--text-color);
  padding: 8px;
  box-sizing: border-box;
}

.custom-input:focus {
  outline: none;
  border: 1px solid #1e4c88;
}

.textinput-label {
  font-size: 0.8rem;
  font-weight: bold;
  color: #07032b;
  color: var(--text-color);
  margin-bottom: 16px;
  display: block;
  letter-spacing: 0.02rem;
}

.textinput-description {
  font-size: 0.7rem;
  color: #5f5d73;
  color: var(--light-text-color);
  font-weight: normal;
  margin-top: 3px;
}

.textinput-container .textinput-error {
  color: #c34343;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 600;
}

.textinput-container input:disabled {
  background-color: #eceff7;
  cursor: not-allowed;
  opacity: 0.5;
}

.textinput-container .textinput-info-text {
  font-size: 12px;
  margin-top: 8px;
  width: 100%;
  text-align: right;
}
