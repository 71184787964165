.custom-icon-label-button {
  border: none;
  //font-family: "Muli" !important;
  font-weight: bold;
  font-size: 0.8rem;
  letter-spacing: 0.02rem;
  color: #3C598C;
  //color: var(--secondary-color);
  cursor: pointer;
  background: none;
}

.custom-icon-label-button:focus {
  outline: none;
}

.custom-icon-label-button-icon {
  margin-right: 5px;
}

.custom-icon-label-button .download-link {
  text-decoration: none;
  color: #1e4c88;
  color: var(--secondary-color);
}

.custom-icon-label-red {
  color: #ca1c00;
}

.custom-icon-label-button .icon-add::before {
  color: #1e4c88;
  color: var(--secondary-color);
}

.custom-icon-label-button:hover {
  opacity: 0.7;
}
