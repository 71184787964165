.button-toggle-wrapper {
  display: flex;
}

.button-toggle-wrapper .button-toggle-label {
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.02rem;
  margin-right: 16px;
  display: inline-block;
  margin-top: 7px;
}

.button-toggle-wrapper .button-toggle-switch {
  border-radius: 5px;
  overflow: hidden;
}

.button-toggle-wrapper .button-toggle {
  border: none;
  background: #eceff7;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.8rem;
  letter-spacing: 0.02rem;
  font-family: "Muli";
  font-weight: 600;
}

.button-toggle-wrapper .active {
  background-color: #1e4c88;
  color: #fff;
}
