.school-dropdown {
  .school-dropdown-item {
    border: 1px solid #eceff7;
    border-top: 0;
  }
  .dropdown-menu .dropdown-item {
    padding: 6px 12px;
  }
}

.sub-questions {
  .card-header {
    padding: 20px 10px;
  }
}

.quiz-question-editor-card .flex-container {
  display: flex;
}

.quiz-question-editor-card .match-header-wrapper {
  background-color: #eceff7;
  font-weight: bold;
  flex: 1 1;
}
.quiz-question-editor-card .match-header-wrapper input {
  background-color: transparent;
  border: none;
  font-size: 0.8rem;
  font-weight: bold;
}

.quiz-question-editor-card .textinput-container {
  margin: 0 0px 0 0;
}

.quiz-question-editor-card .match-editor {
  border: 1px solid #bec5d9;
  margin-bottom: 0px;
}

.smart-quiz-add-more .dropdown-item:hover,
.dropdown-item:focus {
  background-color: #0923e6;
  color: #ffffff;
}
