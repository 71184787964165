nav {
  margin: auto;
}
nav > .nav-item {
  cursor: pointer;
}

.content-container > div {
  display: none;
}
.content-container[data-dispmode="uploader"] > div#uploader {
  display: block;
}
.content-container[data-dispmode="url"] > div#url,
.content-container[data-dispmode="existing"] > div#existing,
.content-container[data-dispmode="integrated"] > div#integrated {
  display: block;
}

#existing nav > .nav-item[data-rb-event-key="viewDocument"] {
  display: none !important;
}
#existing .knowledge-box > .tab-content {
  padding-top: 0 !important;
}
.btn-primary {
  cursor: pointer;
}
.section-title {
  padding: 15px 15px 23px;
  text-align: center;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.flex.page-mid-wraper-build-v2 {
  border: solid 1px #cdd5ef;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}
.flex.page-mid-wraper-build-v2 > div:nth-child(1) {
  padding: 1rem 0 0 1rem;
}
.flex.page-mid-wraper-build-v2 > div:nth-child(2) {
  flex: 1;
  padding: 1rem;
}
.flex.page-mid-wraper-build-v2 > div:nth-child(3) {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 1rem 1rem 0 0;
}
.flex.page-mid-wraper-build-v2 > div:nth-child(4) {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 1rem 1rem 0 0;
}
.flex > button {
  margin: 1rem auto;
}
.webfield-add {
  border: solid 1px #0923e6;
  border-radius: 0.25rem;
}
.webfield-del {
  border-radius: 0.25rem;
  border: solid 1px #d00026;
}
.webfield-btn {
  margin: 0 0.25rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
}
.webfield-add > img {
  width: 1rem;
  height: 1rem;
}

.webfield-parent.hideadd .webfield-add,
.webfield-parent.hidedel .webfield-del {
  display: none;
}
.build-knowledge-v2 {
  section > .container {
    position: relative;
    height: 100%;
  }
}
.content-container {
  position: relative;
  height: calc(100% - 180px);
}
.content-container > #existing {
  overflow: hidden;
  position: relative;
  height: 100%;
}
#existing > div,
#existing > div > div,
#existing > div > div > div:first-child {
  position: relative;
  height: 100%;
}

.error-bg {
  background-color: #fefaeb;
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

nav > .nav-item.active {
  color: #0923e6 !important;
  background-color: transparent !important;
  
  &::after {
    width: 100%;
  }
}