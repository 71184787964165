.document-search-popup {

  &.modal.show .modal-dialog {
    max-width: 90%;
  }
  .modal-content{
    // height: 80vh;
  }

  .doc-previews{
    background-color: #F0F4FF;
    border-radius: 8px;
  }
  .page-input {
    width: 50px;
    height: 30px;
  }
  .page-select {
    height: 30px;
    padding: 0;
    justify-content: end;
    padding-right: 8px;
    align-items: center;
  }
  .btn.add-page{
    font-size: 14px;
    padding-top: 4px;
    border-radius: 0;
  }

  .docs-container {
    max-height: calc(80vh - 390px);
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .select-doc-preview {
    height: calc(100% - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .doc-row.select{
    background: #F0F4FF;
  }
  .doc-row{
    display: flex;
    align-items: center;
    word-break: break-all;

    &.selected{
      background: #E6EDFF;
    }
  }

  .pdf-viewer-wrapper {
    padding: 0;
    margin-top: 10px;
    .pdf-viewer-page-controls {
      bottom: -40px;
      width: 100%;
      left: unset;
      transform: unset;
      justify-content: center;
    }
  }
  .doc-search {
    input.form-control {
      background-image: url("../../../assets/images/search-blk.svg");
      background-repeat: no-repeat;
      background-position: 11px 50%;
      padding-left: 40px;
      background-color: #ECEFF7;
      border-radius: 8px;
      resize: none;
      border: 1px solid #CDD5EF;
      padding: 7px 15px;
      padding-left: 35px;

    }
  }
  .page-warning{
    background-color: #FFF3CD;
    border: 1px solid #F9B234;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
  }
}