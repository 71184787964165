.shadow-card {
  box-sizing: border-box;
  box-shadow: 5px 5px 16px rgba(215, 214, 230, 0.5);
  padding: 24px;
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #fff;
  transition: all 0.2s ease-in-out;
}

.clickable-card {
  cursor: pointer;
}

.clickable-card:hover {
  /* opacity: 0.7 !important; */
  box-shadow: 0 12px 16px rgba(215, 214, 230, 0.5) !important;
}
