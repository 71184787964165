.quiz-input-card-wrapper-anchor {
  display: flex;
  width: 100%;
  /* margin-bottom: 32px; */
  color: #151418;
}

.quiz-input-card-wrapper-anchor:last-child {
  /* margin-bottom: 0; */
}

.quiz-input-card {
  background-color: #fff;
  /* box-shadow: 3px 0 10px rgba(215, 214, 230, 0.5); */
  position: relative;
  border-radius: 0 10px 10px 0;
  flex: 1;
}

.quiz-input-card .textinput-container {
  width: 100%;
}

.quiz-input-card .textinput-container textarea {
  overflow: hidden;
  resize: none;
  border: none;
  border-bottom: 1px solid #eceff7;
  border-radius: 0;
  background-color: transparent;
  min-height: 36px;
}

.quiz-input-card input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #eceff7;
  border-radius: 0;
  margin-bottom: 10px;
  background-color: transparent;
}

.quiz-input-card .markdown-container .textinput-container input {
  border: 1px solid #bec5d9;
  border-radius: 3px;
  margin-bottom: 0;
}

/* .quiz-input-card:hover {
  transition: all 0.1s ease;
  border: 1px solid var(--secondary-color);
  backface-visibility: hidden;
} */

.quiz-input-card-close-button {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 12px;
  z-index: 1;
  font-size: 0.7rem;
}

.quiz-input-card-close-button .icon-close::before {
  color: #07032b;
  color: var(--text-color);
}

.quiz-input-card-close-button:focus {
  outline: none;
}

.quiz-input-card-label {
  margin-right: 0.5rem;
  font-weight: bold;
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-top: 1px;
}

/* .quiz-input-card-wrapper-anchor:focus-within .quiz-input-card {
  border: 1px solid var(--secondary-color);
} */

.quiz-input-card-wrapper-anchor .add-button-container {
  text-align: right;
  width: 100px;
  margin-left: 8px;
  box-sizing: border-box;
  padding: 16px 0;
}

.quiz-input-card .primary-section,
.quiz-input-card .secondary-section,
.quiz-input-card .primary-add-section,
.quiz-input-card .secondary-add-section {
  padding: 5px 32px 5px 20px;
  position: relative;
}

.quiz-input-card .number-section-label {
  padding-top: 10px;
}

.quiz-input-card .primary-add-section input {
  border-color: #1e4c88;
  border-color: var(--secondary-color);
}

.quiz-input-card .primary-section {
  /* background-color: #f1f7ff;number-section-label */
}

.quiz-input-card .primary-add-section {
  background-color: #d6e2f4;
}

.quiz-input-card .secondary-section {
  background-color: #fcfcf7;
}

.quiz-input-card .secondary-add-section {
  background-color: #f7f7ee;
}

.quiz-input-card .primary-section-label,
.quiz-input-card .secondary-section-label {
  position: absolute;
  left: -25px;
  top: 0;
  font-size: 0.7rem;
  color: #fff;
  font-weight: 600;
  padding: 5px;
  border-radius: 16px 0px 0 16px;
  width: 16px;
  height: 57px;
}

.quiz-input-card .primary-section-label span,
.quiz-input-card .secondary-section-label span {
  transform: rotate(-90deg);
  display: inline-block;
  position: absolute;
  left: -12px;
  top: 24px;
  width: 48px;
  text-align: center;
}

.quiz-input-card .secondary-section-label span {
  left: -17px;
}

.quiz-input-card .primary-section-label {
  background-color: #1e4c88;
  background-color: var(--secondary-color);
}

.quiz-input-card .secondary-section-label {
  background-color: #8d8d79;
}

.quiz-input-card .textinput-container input:focus {
  border-top: none;
  border-left: none;
  border-right: none;
}

.quiz-input-card .markdown-container .textinput-container input:focus {
  border: 1px solid #bec5d9;
}

.quiz-input-card .bullet-input {
  flex: 1;
}

.quiz-input-card .keyword-input {
  margin-left: 24px;
  width: 288px;
}

.quiz-input-card .keyword-input .custom-input {
  /* color: blue; */
}

.quiz-input-card .score-input {
  margin-left: 24px;
  width: 100px;
}

.quiz-input-card .score-input .custom-input {
  width: 100%;
  min-width: 100%;
}
