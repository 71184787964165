.sticker-picker {
  border: 1px solid #eceff7;
  border-radius: 5px;
}

.sticker-picker-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 270px;
  padding: 0 6px 6px 6px;
  will-change: transform;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.sticker-set-tray {
  /* margin-bottom: 16px; */
  display: flex;
  width: 319px;
  height:60px;
  white-space: nowrap;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  padding: 6px 6px 0;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.sticker-set-thumbnail {
  width: 50px;
  background-color: #eee;
  float: none;
  height: 90%;
  margin: 0 0.25%;
  display: inline-block;
  zoom: 1;
  cursor: pointer;
  background-color: #fff;
  border: none;
}

.sticker-set-thumbnail-img {
  width: 100%;
  height: auto;
}

.sticker-item {
  width: 100px;
  border: none;
  background-color: #fff;
  cursor: pointer;
  border-radius: 3px;
}

.sticker-img {
  width: 100%;
  height: auto;
}

.sticker-item:hover {
  background-color: #eceff7;
}
