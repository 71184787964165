.header-tabs-wrapper {
  position: sticky;
  top: 0px;
  z-index: 210;
  margin: 0;
  left: 0px;
  padding: 24px 0px 24px;
  background-color: #fff;
}

.header-tabs-wrapper .header-tab {
  color: #5f5d73;
  background: #ffffff;
  border: none;
  font-size: 0.8rem;
  margin-right: 20px;
  padding: 8px 16px;
  border-radius: 5px;
  font-weight: 600;
  letter-spacing: 0.02rem;
  cursor: pointer;
  text-transform: capitalize;
}

.header-tabs-wrapper .header-tab-active {
  color: #f9b234;
  background: #fff8eb;
  font-weight: bold;
}

.extract-quiz-wrapper {
  .header-tabs-wrapper {
    position: relative !important;
  }
}
