.quiz-table-wrapper {
    display: flex;
    align-items: flex-start;
  }
  
  .quiz-table {
    width: 100%;
  }
  
  .quiz-table,
  .quiz-table-row-cell,
  .quiz-table-header-cell {
    border: 1px solid #bec5d9;
  }
  
  .quiz-table-header-cell,
  .quiz-table-header-cell .custom-input {
    background-color: #eceff7;
    font-weight: bold;
  }
  
  .quiz-table-row-cell .custom-input,
  .quiz-table-header-cell .custom-input {
    resize: none;
    font-size: 0.8rem;
  }
  
  .quiz-table-row-cell .custom-input,
  .quiz-table-header-cell .custom-input {
    min-width: 0 !important;
    border: none;
  }
  
  .quiz-table-header-cell {
    position: relative;
  }
  
  .quiz-table-wrapper .column-remove-btn {
    position: absolute;
    right: 0;
    top: 8px;
    display: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0 5px;
  }
  
  .quiz-table-wrapper .quiz-table-row-toolbar {
    position: absolute;
    right: -56px;
    top: 12px;
    min-width: 50px;
  }
  
  .quiz-table-wrapper .quiz-table-row-toolbar .row-add-btn {
    background-color: transparent;
    cursor: pointer;
    border: none;
    font-size: 0.8rem;
  }
  
  .quiz-table-wrapper .row-remove-btn {
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0 5px;
  }
  
  .quiz-table-wrapper .column-add-btn {
    margin-left: 5px;
  }
  
  .quiz-table-header-cell:hover .column-remove-btn {
    display: block;
  }
  
  .quiz-table-row {
    position: relative;
  }
  
  .quiz-table-wrapper .icon-delete {
    color: #c34343;
    font-size: 0.75rem;
  }
  
  .quiz-table-wrapper .custom-popover *,
  .quiz-table-wrapper .custom-popover {
    /* position: unset !important; */
    /* overflow: unset !important; */
    word-break: initial;
  }
  
  .quiz-table-wrapper .custom-popover .popover-box {
    background-color: rgba(30, 76, 136, 0.9);
    border-radius: 3px;
    padding: 16px;
    max-width: 450px;
    min-width: 100%;
    color: #fff;
    font-weight: bold;
    font-size: 0.7rem;
    min-width: 200px;
  }
  
  .quiz-table-wrapper .custom-popover .popover-box .textinput-label {
    color: #ffffff;
  }
  
  .quiz-table-popover-arrow {
    color: rgba(30, 76, 136, 0.7);
  }
  
  .quiz-table-row-cell {
    position: relative;
  }
  
  .quiz-table-wrapper .quiz-table-cell-toolbar {
    position: absolute;
    right: 10px;
    top: 8px;
    display: none;
  }
  
  .quiz-table-row-cell:hover .quiz-table-cell-toolbar {
    display: block;
  }
  
  .quiz-table-row-cell .cell-property-btn {
    background-color: transparent;
    border: none;
    font-size: 0.8rem;
    cursor: pointer;
    color: #108e68;
  }
  
  .remove-up-down-arrow {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .quiz-table-master-container{
    .quiz-table{
      background: white;
      border-radius: 8px;
      border-top-left-radius: 0;
    }


    .quiz-table-row-cell {
      background: white;
      border-left: 2px solid #ffe6f1!important;
      border-left: 2px solid #ffe6f1!important;
    }

    .quiz-table-header-cell{
      background: white!important;
      vertical-align: top;
    }
    
    th.quiz-table-header-cell.remove-up-down-arrow:nth-of-type(1) {
      color: white!important;
      background: #0923E6 !important;
      vertical-align: middle;
      border-top-left-radius: 5px;
    } 
    th.quiz-table-header-cell.remove-up-down-arrow {
      background: #F0F4FF !important;
    }
    .quiz-table-header-cell.remove-up-down-arrow .custom-input:last-child {
      background: #F0F4FF !important;
    }
    table.quiz-table {
      background: white;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      border: 1px;
    }

    .page-count{
      background: white;
      text-align: right;
      font-size: 12px;
      color: #6B7085;
      width: calc(100% - 36px);
      padding: 4px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top: 1px solid #6B7085;

      &.des{
        width: calc(100% - 51px);
      }
    }
  }

  .rubric-breakdown {
    .quiz-table-header-cell,
    .quiz-table-row-cell  {
      .custom-input {
        font-size: 14px !important;
        font-weight: normal !important;
      }

      .textinput-container {
        >input {
          text-overflow: ellipsis !important;
        }
      }
    }

    .quiz-table-header-cell.input-filled {
        background: #F0F4FF !important;
      }
  }