.custom-toggle .switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 17px;
  margin-top: 2px;
}

.custom-toggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom-toggle .slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.agent-settings .custom-toggle input:checked + .slider {
  background-color: #0923E6;
}

.agent-settings .custom-toggle input:focus + .slider {
  box-shadow: 0 0 1px #0923E6;
}

.custom-toggle input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.custom-toggle .slider.round {
  border-radius: 34px;
}

.custom-toggle .slider.round:before {
  border-radius: 50%;
}

.custom-toggle .toggle-label {
  font-size: 0.9rem;
  vertical-align: top;
  margin-left: 8px;
}

.general-settings .custom-toggle .toggle-label {
  margin-left: 0;
}