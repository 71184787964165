.portal-data-table {
  .search-filter {
    flex: 1;

    .search-text {
      background-image: url("../../../assets/images/search-blk.svg");
      background-repeat: no-repeat;
      background-position: 11px 50%;
      padding-left: 40px;
      background-color: #eceff7;
      border-radius: 8px;
      resize: none;
      max-width: 340px;
      height: auto;
    }
  }
  .rdt_TableBody {
    border: none;
  }

  .action-button {
    padding: 5px;
    &.red {
      border-color: red;
    }
  }

  .expand-intent {
    box-shadow: 0px 4px 4px #b9b6e0;
    border-radius: 8px;
    border-left: 1px solid #b9b6e0;
  }

  .badge {
    font-size: 12px;
    padding: 4px 16px;
    margin: 3px;

    &.badge-primary {
      background-color: #0923e6;
    }
  }

  .headers {
    display: flex;
    margin-bottom: 2px;
    background-color: #e6edff;
    height: 50px;
    border-radius: 8px 8px 0px 0px;
  }

  .header-cell {
    padding: 10px 16px;
    display: flex;
    align-self: center;
    flex-basis: 0;
    flex-shrink: 0;
    max-width: 100%;
    min-width: 100px;
    height: 100%;
    align-items: center;
    justify-content: space-between;

    &:nth-of-type(1) {
      padding-left: 40px;
      border-radius: 8px 0px 0px 0px;
    }

    &.grow1 {
      flex-grow: 1;
    }
    &.sorted {
      background: #0923e6;
      color: white;
    }
  }

  .table-row {
    display: flex;
    position: relative;
    box-shadow: 0 4px 4px #b9b6e0;
    border-left: 1px solid #cdd5ef;
    border-radius: 8px;
    margin-bottom: 20px;
    &:first-child {
      padding-left: 25px;
    }
    &.suggested {
      border-left: 3px solid rgba(255, 0, 158, 0.7);
    }
    .row-cell {
      flex-basis: 0;
      flex-shrink: 0;
      max-width: 100%;
      min-width: 100px;
      display: flex;
      align-items: center;
      padding: 16px;
      &.grow1 {
        flex-grow: 1;
      }
    }
  }
  .body-rows {
    max-height: calc(100vh - 312px);
    overflow-y: auto;
    display: block;
    padding-top: 4px;
    padding-bottom: 40px;
    
    &::-webkit-scrollbar {
      width:5px;
    }
    
    &::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    &::-webkit-scrollbar-thumb {
      background: #888; 
    }
    
    &::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }
  }
  .toggle-btn {
    gap: 10px;
    display: flex;
    align-items: center;
    background: none;
    border-radius: 8px;
    border: none;
    margin: 0;
  }
  .chev.expanded {
    transform: rotate(90deg);
  }

  .sort.asc {
    transform: rotate(180deg);
  }
  .no-btn{
    border: 0;
    background: none;
  }
}
