.containerClass {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 3px 4px 0px #b9b6e0;
  margin-bottom:1rem;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4.5rem;
    padding: 0 2rem;
    background: #e6edff;
    border-radius: 8px 8px 0 0;
    > div {
      h2,
      h3,
      h4,
      h5 {
        margin: 0;
      }
      h3,
      h5 {
        font-size: 0.9rem;
        color: #999fb3;
        font-weight: 700;
      }
      h2 {
        font-size: 1.1rem;
      }
      h4 {
        font-size: 1rem;
      }
    }
  }
  .content {
    padding:1.5rem 2rem;
    h3 {
      font-size: 0.9rem;
      color: #999fb3;
    }
    > div,.class-tag {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      &:first-of-type {
        margin-bottom:24px;
      }
      .moduleWrapper {
        padding: 0.3rem 1rem;
        background-color: #eceff7;
        border-radius: 1rem;
        font-size: 0.8rem;
        color: #0923e6;
        font-weight: 600;
      }
    }
  }
}
