.classHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background: #e6edff;
  border-radius: 8px 8px 0 0;
  > div {
    h2,
    h3 {
      margin: 0;
    }
    h3 {
      font-size: 0.9rem;
      color: #999fb3;
      font-weight: 700;
    }
    h2 {
      font-size: 1.1rem;
      word-break: break-word;
    }
  }
}
.classFlex {
  display: flex;
  align-items: center;
  margin: 0.75rem 1.25rem 1.2rem;
}
.modulesContainer {
  border-radius: 8px;
  box-shadow: 0px 4px 4px #b9b6e0;
  .header {
    background-color: #eceff7;
    padding: 0 1.25rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px 8px 0 0;
    h3,
    h4 {
      margin: 0;
      font-weight: bold;
    }
    h3 {
      font-size: 1rem;
    }
    h4 {
      font-size: 0.8rem;
    }
  }
  .removePadding {
    padding-left: 0;
  }
  .disabled {
    background-color: #eceff7;
    width: 100%;
    h3 {
      color: #7a7a7a;
    }
  }
  .moduleContainer {
    display: flex;
    padding: 0 1.25rem;
    height: 3rem;
    align-items: center;
    justify-content: space-between;
    h3 {
      font-size: 0.9rem;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
}
.flexButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.content-scroll{
  display: block;
  height: calc(100vh - 600px);
  overflow: auto;
  width: 100%;
  min-height: 100px;
}

.page-mid-wraper{
  &.user-groups{
    height: calc(100% - 85px);
  }
  .list-unstyled.content-scroll{
    height: calc(100vh - 483px);
  }
}

@media (max-height: 501px) {
  .page-mid-wraper {
    height: 100%;
  }


}
