@import "../../../src/variables.scss";

.heading-plan {
  font-size: 28px;
  font-weight: 700;
}

.plan-iframe {
  width: 100%;
  height: 1400px;
  border: none;
}

.plan-section-wrapper {
  padding: 32px;
  border-radius: 24px;
  border: 2px solid $bg-table;
  &.selected{
    border-color: $clr-txt-primary;
  }

  .btn.btn-primary:disabled {
    color:  $clr-txt-primary;
    opacity: 0.6;
    pointer-events: none;
  }

  .recommened {
    background-color: $bg-grey-hover;
    padding: 4px 16px;
    color: $clr-txt-primary;
    border-radius: 4px;
  }

  .name {
    font-size: 28px;
    color: $clr-highlight;
    font-weight: 700;
    padding: 8px 0;
  }

  .sub {
    font-size: 18px;
    color: $clr-placeholder;
  }

  .price{
    padding: 24px 0;
    color: $clr-placeholder;

    .month {
      font-size: 24px;
      font-weight: 700;
    }

    .year {
      font-size: 18px;
    }
  }

  .feature-label {
    padding-top: 24px;
    font-size: 18px;
    color: $clr-placeholder;
    font-weight: 700;
  }

  .feature-item {
    padding: 8px 0;
    font-size: 18px;
  }

  .feature{
    padding: 0 8px
  }

  .compare-table {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
table.compare-table {
  border-collapse: collapse;
  overflow-x: auto;
  table-layout: fixed;
  width: 100%;
  
  img.check-plan{
    height: 18px;
  }
  
  th, td {
    text-align: center;
    padding: 15px;
  }
  tbody tr td:nth-child(odd):not(:first-child) {
    background-color: rgba(9, 35, 230, 0.07);
    // background-color: #e2e5f5;
  }

  th {
    font-weight: 700;
  }

  tbody tr  {
    border-bottom: 1px solid rgb(236, 239, 247);
  }
}
.reach-out {
  color:$clr-txt-primary;
  text-decoration: underline;
}
.acc-question {
  background: none;
  border: none;
  text-align: left;
  font-weight: 700;
  font-size: 18px;
  padding: 20px 0;
  
}

.qna-container {
  border-top: 1px solid $bg-grey-hover;
  // border-bottom: 1px solid $bg-grey-hover;
  font-size: 18px;
}
.qnas .qna-container:last-child{
  border-bottom: 1px solid $bg-grey-hover;
}

.plan-bg{
  position: relative;
}

.banner-text {
  font-size: 40px;
}

.center-banner-text{
  position: absolute;
  left: 25%;
  top: 35%;
  z-index: 1;
}

.current-subscription {
  .plan-section-wrapper {
    .name {
      color: $clr-primary;
    }
    .border-right {
      border-right: 2px solid #BEC3D1 !important;
    }
    .all-features {
      display: grid;
      grid-template-columns: repeat(2, 1fr); 
      .feature-item {
        .feature {
          color: $clr-txt-primary;
        }
      }
    }
  }
}