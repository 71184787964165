// primary text color
$clr-primary: #0E0748;
$border-clr-primary: #0E0748;
$bg-clr-primary: #0E0748;

// primary color blue
$clr-txt-primary:#0923E6;

// primary BG color
$bg-primary:#0923E6;

// border color primary
$border-primary:#0923E6;

// hover background primary
$bg-hover-primary:#0923E6;

// button BG primary color
$btn-bg-primary:#0923E6;

// button BG hover primary color
$btn-bg-hover-primary:#0923E6;

// button text primary color
$btn-clr-primary:#0923E6;

// text color highlight for hover, focus
$clr-highlight: #FF009E;

// background color highlight
$bg-highlight: #FF009E;
$bg-highlight-1:#FFE6F1;
$bg-highlight-2:#FEFAEB;

// background color highlight with 50%
$bg-highlight-50: rgba(255, 0, 158, 0.5);

// text color error msg
$clr-error: #D00026;

// button BG danger
$btn-bg-danger:#D00026;

// button border danger
$btn-border-danger:#D00026;

// border color for error 
$border-error-clr:#D00026;

// text color success msg
$clr-success: #00864D;

// text color white for SECONDARY/SURFACE
$clr-white: #ffffff;

$clr-black: black;

// BG color white
$bg-white: #FFFFFF;

// button BG color white
$btn-bg-white: #FFFFFF;

// HELPTEXT color dark grey 
$clr-helptext: #737088;

// border color common
$clr-border: #CDD5EF;
$clr-border-grey5:#BEC3D1;

// shadow color common
$clr-shadow: #B9B6E0;

// text color grey 
$clr-grey: #ECEFF7;

// background color grey for login and loading screens
$bg-grey: #ECEFF7;

// text color grey 
$border-grey: #ECEFF7;

// dropdown box shadow rgba color
$dropdown-shadow:rgba(154, 165, 255, 0.16);

// greeting chat BG grey
$greeting-bg-grey:rgba(205, 213, 239, 0.3);

// greeting chat header BG
$chat-bg-header:#0923E6;

// greeting chat text pink BG
$chat-bg-pink:#FF009E;

// greeting chat text grey BG
$chat-bg-grey:#CDD5EF;

// greeting chat tag button BG
$btn-tag-bg:#CDD5EF;

// input placeholder color
$clr-placeholder:#6B7085;

//text editor BG
$txt-editor-bg:rgba(255,0,158,0.05);

// upload documents file BG 
$upload-doc-bg: #fbfcff;

// button help BG 
$btn-help-bg: #f9b234;

// border yellow
$border-yellow: #f9b234;

// bg yellow
$bg-yellow: #f9b234;

// text yellow
$clr-yellow: #f9b234;

$clr-info: #FFEEBA;

// input fild BG 
$input-bg:#F5F6FA;

// section BG grey 
$sec-bg-grey:#F5F6FA;

//generate question error border color 
$ques-error-border:rgba(255,0,158,0.5);

//all assessment review caption bg
$bg-caption:rgba(255,0,158,0.7);

//generate question error msg BG color 
$ques-error-bg:rgba(208,0,38,0.2);

// tooltip popover BG 
$popover-bg:rgba(14,7,72,0.9);

$popover-bg-notransparent:rgba(14,7,72,1);

$clr-notify: #737088;

$clr-grey6:#9398AB;
$clr-grey7:#6B7085;
$bg-grey-hover:#E6EDFF;
$grey-txt: #495057;
// assesment question badge BG 
$badge1:#6DCED8;
$badge2:#E5C27F;
$badge3:#E5C27F;
$badge4:#B2E57F;
$badge5:#EEEA75;

$chart-edge:rgb(140, 153, 194);
$password-bg:#f9b234;
$orange:#EEB764;
$bg-table:#F0F4FF;
$orange-review:#EEB550;
$bg-page-wrapper: #FEFAEB;
$bg-mark1:#C7D6FF;
$bg-mark2:#839EFF;
$bg-mark3:#304EF2;


// mixins
@mixin shadow() {
    box-shadow: 0px 16px 16px 0px rgba(185,182,224,0.16);
}
@mixin radius($radius) {
    border-radius: $radius;
}
@mixin reactdropdown() {  
    .select-greeting-user-group__control{
        @include radius(10px);
        border-color: $clr-border;
        min-height: 47px;
        padding: 0 15px 0 7px;
        cursor: pointer;
        .select-greeting-user-group__placeholder{
            color: $clr-primary;
        }
        @media(max-width:767px){
            margin-bottom: 12px;
        }
    }
    .css-26l3qy-menu{ // react-select__menu
        @include radius(5px);
        box-shadow: 0px 0px 12px 0px $dropdown-shadow !important;
        background-color:$bg-white;
        z-index:2;
        > div > div{
          background-color:$bg-white;
          cursor: pointer;
          font-size: 15px;
          padding: 10px 15px;
          color: $clr-primary;
          &:hover{
            background-color:$bg-hover-primary;
            color: $clr-white;
          }
        }
    }
}
