.extract-quiz-info .header-tabs-wrapper {
  padding: 0;
  background: transparent;
}

.extract-quiz-info .header-tab-active {
  font-weight: bold;
  background: none;
  border-bottom: 2px solid #5f5d73;
  border-radius: 0;
  color: #5f5d73;
}

.extract-quiz-info .header-tab {
  background-color: transparent;
  border-radius: 0;
}

.extract-quiz-info .header-tab:hover {
  border-bottom: 2px solid #ccc;
}

.extract-quiz-info .info-block {
  background-color: transparent;
  border: 0;
  margin: 16px 0 !important;
  padding: 0;
  font-size: 0.7rem;
  font-weight: 600;
}

.extract-quiz-info {
  background-color: #eceff7;
  border-radius: 5px;
  padding: 15px;
}

.extract-quiz-info .custom-button {
  float: right;
}

.extract-quiz-info .quiz-info-extract {
  background: white;
  padding: 15px;
  border-radius: 5px;
  margin: 10px 0 15px;
  height: calc(100vh - 320px);
  overflow-y: auto;
}

.extract-quiz-info .quiz-info-extract pre {
  overflow: auto;
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.extract-quiz-info * {
  box-sizing: border-box;
}

.extract-quiz-info .table-preview-content {
  height: calc(100vh - 236px);
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  margin: 10px 0 15px;
  overflow: auto;
}

.extract-quiz-info .csv-converted-table,
.extract-quiz-info .csv-converted-table tr,
.extract-quiz-info .csv-converted-table td {
  border: 1px solid black;
  border-collapse: collapse;
}

.extract-quiz-info .csv-converted-table {
  table-layout: fixed;
  width: 100%;
  font-size: 6px;
}

.extract-quiz-info .csv-converted-table td {
  word-wrap: break-word;
}

.extract-quiz-info .table-miniature {
  margin-bottom: 24px;
  position: relative;
}

.extract-quiz-info .table-miniature-overlay {
  position: absolute;
  top: 0;
  /* bottom: 0; */
  /* left: 0; */
  right: 0;
  background-color: rgba(215, 214, 231, 0.8);
  display: none;
  padding: 8px;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: bold;
}

.extract-quiz-info .table-miniature:hover .table-miniature-overlay {
  display: flex;
  align-items: center;
}

.extract-quiz-info .table-miniature-overlay .icon-copy {
  font-size: 16px;
  margin-right: 8px;
}

.extract-quiz-info .table-miniature-overlay p {
  margin: 0;
  color: #07032b;
}

@media (min-width: 768px) {
  .extract-quiz-info {
    display: inline-block;
    width: 300px;
    vertical-align: top;
  }
  .edit-extract-quiz {
    .extract-quiz-questions {
      width: calc(100% - 300px);
      padding-left: 1.25rem;
    }
  }
  .create-quiz-upload-doc {
    .extract-quiz-info {
      height: 100%;
    }
  }

  .preview {
    .create-quiz-upload-doc {
      .table-preview-content,
      .quiz-info-extract {
        height: calc(100vh - 300px);
      }
    }
  }
}

.extract-quiz-info .modal {
  padding-top: 40px;
}
.extract-quiz-info .modal-content {
  max-width: 500px;
  width: 100%;
}
.extract-quiz-info .modal-content-body {
  padding: 0;
  text-align: center;
}
.extract-quiz-info .modal-content-body img {
  max-width: 100%;
  max-height: 415px;
}

.extract-quiz-info .highlighted-content {
  color: #ff009e;
}
