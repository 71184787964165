.create-quiz-submission-details-page-container {
  .icon-left-chevron {
    font-size: 0.65rem;
  }

  .footer-action-btn-container {
    padding: 16px;
    text-align: right;
    background-color: #f9fafd;
    border-radius: 0 0 10px 10px;
  }

  .flex-one,
  .flex-two {
    padding: 0 12px;
  }

  .flex-two {
    flex: 2;
  }

  .table-row {
    border-bottom: 1px solid #eceff7;
    padding: 8px 12px 0;
    position: relative;

    .flex-container {
      display: flex;
    }

    .flex-one {
      flex: 1 1;
      font-size: 0.875rem;

      p {
        font-size: 0.875rem;
      }

      input.form-control {
        height: auto;
        border-radius: 6px;
      }
    }
  }
}

.submission-data-wrapper {
  border: 1px solid #cdd5ef;
  border-radius: 10px;

  .submission-data-table-label {
    color: #07032b;
    font-weight: bold;
    font-size: 0.8rem;
    margin-top: 0.875rem;
  }

  .review-number {
    background-color: #0923E6;
    color: #fff;
    font-weight: 600;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    left: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dashed-separator {
  border-top: 1px dashed #eceff7;
  margin: 0px 12px 16px;
}

.sub-question-number {
  font-weight: bold;
  color: #1e4c88;
  margin-left: 12px;
  text-decoration: underline;
  font-size: 0.875rem;
}

.conversations-count {
  background-color: #eeb550;
  border-radius: 10px;
  height: 24px;
  padding: 0 9px;
  color: #000;
  font-weight: 600;
  line-height: 24px;
}

.quiz-table-answer-table {
  border-collapse: collapse;

  .quiz-table-header-cell {
    position: relative;
    background-color: #eceff7;
    font-weight: 700;
    border: 1px solid #bec5d9;
  }

  .quiz-table-answer-cell {
    border: 1px solid #eceff7;
    padding: 2px;
  }
}

.rdt_TableBody {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.create-test-file {
  .edit-extract-quiz .extract-quiz-questions {
    overflow-y: auto;
    height: calc(100vh - 300px);
  }

  .extract-quiz-info .quiz-info-extract {
    height: calc(100vh - 500px);
  }
}

.page-mid-wraper.create-test-file {
  padding: 0;
}

.review-questions-list-parent {
  height: 440px;
}

.view-all-submissions {
  .blue-filters {
    .select-target.select-sort>div {
      min-width: 160px;
    }
  }

  .rdt_Table {
    .rdt_TableBody {
      border: 1px solid #CDD5EF;
    }

    .rdt_TableBody::-webkit-scrollbar {
      width: 1px;
    }

    .rdt_TableHead {
      .rdt_TableHeadRow {
        border-top-left-radius: 18px;
        border-top-right-radius: 18px;
      }
    }
  }

  .all-submissions {
    .rdt_TableRow {
      border-bottom: 0px;
    }

    .submissions-data {
      .rdt_Table {
        .rdt_TableBody {
          border: 0px;
          // &:not(:last-of-type) {
          border-bottom: 1px solid #CDD5EF;

          // }
          .rdt_TableRow {
            border-bottom: 0px;
          }

          // border: 1px solid #CDD5EF;
        }
      }
    }
  }

  .questions-data {
    .rdt_TableRow {
      border-bottom-color: #CDD5EF;

      .rdt_TableCell {
        border-bottom-color: #CDD5EF;
        border-right: 1px solid #CDD5EF;

        &:last-child {
          border-right: 0px;
        }
      }
    }
  }

  .cstm-date-wraper {
    z-index: 2;
  }
}

.suneditor-container {
  .sun-editor {
    border-radius: 8px;

    .sun-editor-editable,
    .se-char-counter-wrapper {
      font-family: "Mulish", sans-serif;
    }
  }
}