.add-tag-modal .changepurpose-select-tag {
  min-height: auto;
}

.add-document-modal .modal-dialog {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  margin: 0;

  max-width: 600px;
  width: 100%;
}

.add-document-modal .modal-content {
  height: 100%;
}

.document-items-container {
  flex: 1;
  overflow-y: auto;
}

.document-item {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.document-item-breadcrumb {
  font-size: 12px;
  line-height: 15.6px;
  text-align: left;
  color: #6B7085;
}

.document-item.active {
  background: #e6edff;
}

.document-modal-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.spinner-container {
  padding: 6px 12px 0px 12px;
}

[data-tag="allowRowEvents"] {
  width: 100%;
}
