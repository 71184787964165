.sub-question-modal{
    .flex-one {
        padding: 0 20px;
        &.no-padding{
            padding: 0;
        }
    }
    .sun-editor {
        border:1px solid #bec5d9 !important;
    }
    .page-label {
        font-size: .7rem;
        font-weight: 600;
        letter-spacing: .02rem;
        margin-bottom: 16px;
    }
    .options-editor{
        padding: 0 20px;
        .add-option-btn {
            margin-left: 3rem;
        }
    }
    .card-subheading{
        font-size: 12px;
        font-weight: bold;
    }
}
