.smooth-dnd-draggable-wrapper{
  position: relative;
  padding-right: 35px;
  .drag-handle{
    position: absolute;
    right:0;
    cursor: grab;
    top: 3px;
    font-size: 22px;
    width: 35px;
    text-align: center;
  }
  .quiz-question-editor-card {
    text-align: left;
    margin: 0px 0 24px;
    box-shadow: none;
    background-color: #FEFAEB;
    border: 1px solid #CDD5EF;
    position: relative;
    padding:1.5rem 1.5rem 1.5rem 2.4rem;
    border-radius: 8px;
    .page-label {
      font-size: 0.7rem;
      font-weight: 600;
      letter-spacing: 0.02rem;
      margin-bottom: 16px;
      + .page-label {
        padding-left: 1.25rem;
      }
    }
    .editable-question {
      margin-top: 20px
    }
    &.quiz-question-live-card{
      background-color: #FFE6F1;
      margin-bottom: 50px;
    }

    &.clickable-card.keywords {
      background-color: #FFE6F1;
    }
  }
  .display-question{
    font-size: 12px;
    font-weight: 700;
    color: #000;
    max-width: 90%;
    margin-bottom: 1rem;
    > p{
      font-weight: normal;
      margin: 6px 0 0;
    }
  }
  .option-display-field {
    font-size:12px;
    margin:12px 0;
    display: flex;
    color: #495057;
  }
}

.editable-question{
  .dropdown_wrapper,.textinput-container{
    >  label,.textinput-label{
      font-size: 12px;
      font-family: "Mulish", sans-serif;
      margin-bottom: 8px;
    }
  }
  .quiz-guide-btn-container {
    padding:30px 12px 0;
  }
  .sun-editor{
    border-radius: 8px;
    border:0;
    margin-top: 1rem;
    .sun-editor-editable,.se-char-counter-wrapper{
      font-family: "Mulish", sans-serif;
    }

  }
}
.page-subheading,.bullet-editor-heading{
  font-family: "Mulish", sans-serif;
  font-size: 12px;
  margin-bottom: 8px;
  font-weight: bold;
}
.options-editor{
  font-family: "Mulish", sans-serif;
  .card-subheading{
    font-size: 12px;
    margin-bottom: 8px;
    font-weight: bold;
  }
  .options-editor-checkbox {
    margin-top: 7px;
  }
  .options-editor-delete {
    background-color: transparent;
    border: none;
  }
  .textinput-container{
    padding:0 1rem 0 1.5rem;
  }
  .custom-input{
    font-family: "Mulish", sans-serif;
    font-size: 14px;
  }
  .add-option-btn {
    font-family: "Mulish", sans-serif !important;
    color: #3C598C;
    font-weight: 600;
    font-size: 14px;
    margin-left: 14px;
  }
  
}
.button-toggle-wrapper{
  .button-toggle{
    font-family: "Mulish", sans-serif;
    &.active{
      background-color: #0923E6;
    }
  }
}
.quarter-clearfix{
  height: 8px;
}

.quiz-question-editor-card{
  .quiz-guide-btn {
    border: none;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;
    padding-right: 0;
    text-decoration: none;
    .icon-help-circle{
      color: #0E0748;
    }
  }

  .toolbar-actions{
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 8px 8px 0px 8px;
    background-color: #EDEFF7;
    .toolbar-btn {
      background: transparent;
      border: none;
      cursor: pointer;
      margin: 0 12px;
      border-top: 2px solid transparent;
      padding: 8px 0 8px;
      &:hover{
        border-color: #07032b;
      }
      i{
        &:before{
          color: #000;
          font-size: 16px;
        }
        &.icon-delete{
          &:before{
            font-size: 14px;
            color: #c34343;
          }
        }
      }
      &.delete-toolbar-btn{
        margin-left: 8px;
      }
    }
  }

}


.quiz-question-editor-card .custom-input {
  min-width: 180px;
}

// .quiz-question-live-card {
//   border-color: var(--secondary-color);
// }

.quiz-question-editor-card:hover .quiz-question-card-overlay {
  /* display: block; */
  opacity: 1;
}

.quiz-question-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(30, 76, 136, 0.8);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 32px;
  box-sizing: border-box;
  /* display: none; */
  opacity: 0;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.quiz-question-card-overlay .icon-edit::before,
.quiz-question-card-overlay .icon-copy::before,
.quiz-question-card-overlay .icon-delete::before {
  color: #fff;
  font-size: 20px;
}

.quiz-question-card-overlay .icon-delete::before {
  font-size: 17px;
}

.quiz-question-card-overlay .overlay-btn {
  background: none;
  border: none;
  border-top: 2px solid transparent;
  box-shadow: none;
  margin: 0px 10px;
  cursor: pointer;
  padding: 10px;
  width: 40px;
}

.quiz-question-card-overlay .overlay-btn:hover {
  border-color: #fff;
}


.quiz-question-editor-card .display-options {
  margin: 10px 0 0;
}



.quiz-question-editor-card .radio-circle,
.quiz-question-editor-card .checkbox-square {
  width: 1rem;
  height: 1rem;
  background-color: #fff;
  border: 1px solid #cacece;
  border-radius: 3px;
  display: inline-block;
  margin-right:12px;
}

.quiz-question-editor-card .radio-circle {
  border-radius: 50%;
  /* margin-top: 15px; */
}



.quiz-question-editor-card .mt-20 {
  margin-top: 20px;
}



.quiz-question-editor-card .flex-container {
  display: flex;
}

.quiz-question-editor-card .flex-one {
  flex: 1;
  //padding: 0 20px;
  + .max-width-250{
    padding-left: 1rem;
  }
}

.quiz-question-editor-card .flex-three {
  flex: 3;
  padding: 0 20px;
}

.quiz-question-editor-card .clearfix {
  height: 20px;
}

.quiz-question-editor-card .editable-question-primary-actions {
  position: absolute;
  width: 100%;
  bottom: -16px;
  left: 0;
  text-align: center;
}

// .quiz-question-editor-card .editable-question-primary-actions .primary-btn,
// .quiz-question-editor-card .editable-question-primary-actions .secondary-btn {
//   padding: 5px 16px;
//   border-radius: 3px;
//   font-weight: 600;
//   background-color: var(--secondary-color);
//   border: 1px solid transparent;
//   color: #fff;
//   letter-spacing: 0.02rem;
//   margin: 0 10px;
//   cursor: pointer;
//   font-size: 0.8rem;
// }

// .quiz-question-editor-card .editable-question-primary-actions .secondary-btn {
//   color: #07032b;
//   color: var(--text-color);
//   background-color: #fff;
//   border-color: var(--secondary-color);
// }




// .quiz-question-editor-card .toolbar-actions .toolbar-btn .icon-copy::before,
// .quiz-question-editor-card .toolbar-actions .toolbar-btn .icon-edit::before,
// .quiz-question-editor-card .toolbar-actions .toolbar-btn .icon-new::before,
// .quiz-question-editor-card .toolbar-actions .toolbar-btn .icon-move-up::before,
// .quiz-question-editor-card .toolbar-actions .toolbar-btn .icon-branch::before,
// .quiz-question-editor-card
//   .toolbar-actions
//   .toolbar-btn
//   .icon-move-down::before {
//   color: #000;
//   font-size: 16px;
// }

// .quiz-question-editor-card .toolbar-actions .toolbar-btn .icon-delete::before {
//   font-size: 14px;
//   color: #c34343;
// }

// .quiz-question-editor-card .toolbar-actions .delete-toolbar-btn {
//   margin: 0 12px 0 8px;
// }



.quiz-question-editor-card .text-answer-field {
  padding: 16px 0 8px;
  border-bottom: 1px dotted #bec5d9;
  color: #969696;
  width: 50%;
  font-size: 0.9rem;
}

/* .quiz-question-editor-card td {
  padding: 5px 16px;
  border: 1px solid #ccc;
} */

.quiz-question-editor-card table {
  border-collapse: collapse;
}

.quiz-question-editor-card .card-error {
  background: #c34343;
  font-size: 14px;
  margin: 10px 0;
  font-weight: 600;
  text-align: center;
  color: #fff;
  padding: 10px 0;
}

.quiz-question-editor-card .logic-options-wrapper {
  padding: 0 16px;
}

.quiz-question-editor-card .logic-options-container {
  display: flex;
}

.quiz-question-editor-card .logic-option-text {
  min-width: 200px;
  font-size: 0.9rem;
  flex: 1;
}

.quiz-question-editor-card .logic-options-container .dropdown_wrapper {
  min-width: 200px;
  flex: 1;
}

.quiz-question-editor-card
  .logic-options-container
  .logic-question-select-wrapper {
  flex: 1;
  display: flex;
}

.quiz-question-editor-card .condition-text {
  margin-top: 8px;
  font-size: 0.9rem;
}

.quiz-question-editor-card .dropdown_wrapper {
  z-index: 10;
}
.quiz-question-editor-card .dropdown_wrapper.difficulty {
  z-index: 9;
}

.quiz-question-editor-card .match-row {
  position: relative;
}

.quiz-question-editor-card .match-input-wrapper {
  flex: 1;
  border: 1px solid #eceff7;
}

.quiz-question-editor-card .match-input-wrapper textarea {
  resize: none;
  border: none;
  font-size: 0.8rem;
}

.quiz-question-editor-card .match-header-wrapper {
  background-color: #eceff7;
  font-weight: bold;
  flex: 1;
}

.quiz-question-editor-card .match-header-wrapper input {
  background-color: transparent;
  border: none;
  font-size: 0.8rem;
  font-weight: bold;
}

.quiz-question-editor-card .match-editor {
  border: 1px solid #bec5d9;
  margin-bottom: 0px;
}

.quiz-question-editor-card .match-row-delete {
  background-color: #fff;
  border: none;
  position: absolute;
  right:0px;
  top: 10px;
  cursor: pointer;
  color: #c34343;
}

.logic-question-select-wrapper .dropdown_wrapper {
  z-index: unset;
}

.sortable-item .quiz-question-editor-card .textinput-container,
.sortable-item .quiz-question-editor-card .dropdown_wrapper {
  margin: 0 0px 0 0;
}


.quiz-question-editor-card .flex-one-no-padding {
  flex: 1;
}

// .quiz-question-editor-card .sub-question-add-btn {
//   margin-left: 16px;
// }

// .subquestion-wrapper {
//   margin: 0 16px;
// }

.subquestion-wrapper .accordion-content {
  padding: 20px 0 32px 0;
  position: relative;
}

.subquestion-wrapper .accordion-section {
  background-color: #fffdf8;
}

.quiz-question-editor-card .side-padding {
  padding: 0 16px 0 10px;
}

.quiz-question-editor-card .no-padding {
  padding: 0;
}

.error-sub-question .sub-question-overview {
  border-color: #c34343;
}

.quiz-question-editor-card-wrapper .table-editor-wrapper .custom-input {
  min-width: 0;
}

.quiz-question-editor-card-error {
  border: 1px solid #c34343 !important;
}

.quiz-question-editor-card .done-btn {
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #fff;
  border: 1px solid #1e4c88;
  padding: 7px;
  color: #1e4c88;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 0 0 5px 5px;
  cursor: pointer;
  background-color: #f8faff;
}

.quiz-question-editor-card .done-btn:hover {
  opacity: 0.8;
}

.quiz-question-editor-card .sub-question-overview {
  display: flex;
  border: 1px solid #eceff7;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 24px;
  padding: 16px;
  cursor: pointer;
}

// .quiz-question-editor-card .sub-question-modal .modal {
//   padding-top: 16px;
// }

// .quiz-question-editor-card .sub-question-modal .modal-content {
//   width: 90%;
//   max-width: 1100px;
//   max-height: 620px;
// }

.quiz-question-editor-card .display-description .quiz-table-header-cell {
  background-color: transparent;
}

.max-width-400 {
  max-width: 400px;
}

.max-width-250 {
  max-width: 250px;
}
.save-ques-toltip{
  position: absolute;
  top: 1.4rem;
  left: 1rem;
}

.group-tag {
  position: absolute;
  right: 0px;
  top: 0;
  border-radius: 8px;
  color: white;
  background: #EEB550;
  padding: 4px 16px;
  font-size: 12px;
  border-bottom-right-radius: 0;
}

.confirm-generate-keywords {
  position: absolute;
    background: #E6EDFF;
    padding: 10px;
    width: 265px;
    right: 100%;
    margin-right: 19px;
    top: -5px;
}
.confirm-save-generate-keywords {
  position: absolute;
  background: #E6EDFF;
  padding: 10px;
  width: 265px;
  margin-right: 19px;
  z-index: 9;
  bottom: 50px;
}

.right-triangle {
  width: 0;
  height: 0;
  border-width: 20px 0 20px 20px;
  border-color: transparent transparent transparent #E6EDFF;
  border-style: solid;
  position: absolute;
  top: -6px;
  left: -20px;
}
.bottom-triangle {
  width: 0;
  height: 0;
  border-width: 20px 20px 0 20px;
  border-color: #E6EDFF transparent transparent transparent;
  border-style: solid;
  position: absolute;
  bottom: 30px;
  left: 44%;
}

.subquestion-wrapper {
  background: #FEF2EB;
  padding: 15px;
  margin-bottom: 5px;
}

.toggle-question {
  border: 0;
  background: none;
}

.chev.expanded {
  transform: rotate(90deg);
}

.ref-container {
  .emty-docs-ref {
    min-height: 100px;
    background: #F0F4FF;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .doc-refs-table {
    .title {
      color: #6B7085;
    }
    .ref-table-col-1{
      flex: 1;
    }
    .ref-table-col-2 {
      min-width: 100px;
    }
  }
}
.page-warning {
  align-items: center;
  justify-content: center;
  background: #FFF3CD;
  border: 1px solid #F9B234;
  border-radius: 4px;
  padding: 8px 16px;
  margin-top: 12px;
}