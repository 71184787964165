@import '../../../variables.scss';

.two-button-modal {
    font-family: 'Mulish';
    font-style: normal;
    line-height: 130%;
    max-width: 432px;

    .modal-title {
        /* Text */
        width: 335px;
        height: 23px;
    
        font-weight: 600;
        font-size: 18px;
    
        display: flex;
        align-items: center;
    
        /* Noodle Factory v2.0/Black */
        color: #0E0748;
    
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 1;
    }

    .modal-message {
        margin-top: 1rem;
    
        font-weight: 400;
        font-size: 14px;
    
        // display: flex;
        align-items: center;
        color: #000000;
    
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    }

    .body {
        padding: 0 !important;
        hr {
            background: #9398AB;
            border-radius: 1px;
            margin-bottom: 0 !important;
        }
    }

    .modal-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 16px;
        gap: 12px;
    
        flex: none;
        &.left {
            box-sizing: border-box;
            width: 80px;
            height: 33px;
    
            /* Inside auto layout */
            order: 0;
            flex-grow: 0;
        }
        &.right {
            width: 89px;
            height: 33px;
    
            /* Inside auto layout */
            flex: none;
            order: 1;
            flex-grow: 0;
        }
    }
}
