.bullet-editor {
  box-sizing: border-box;
  border: 1px solid #bec5d9;
  padding: 16px 8px;
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.bullet-editor .bullet-editor-instruction {
  text-align: left;
  font-size: 0.75rem;
  padding: 5px 28px 0;
  font-weight: 600;
  color: #03a8f4;
}

.bullet-editor .bullet-editor-heading {
  margin-bottom: 12px;
  text-align: left;
}

.bullet-editor .side-padding {
  padding: 0 16px !important;
}

.bullet-editor .fix-wd-288 {
  margin-left: 24px;
  min-width: 288px;
}

.bullet-editor .fix-wd-100 {
  min-width: 100px;
  margin-left: 24px;
}
