.save-changes:disabled {
  background: #ECEFF7;
  color: #9398AB;
}

.unread-mark {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #f9b234;
  /* background-color: var(--primary-color); */
  border-radius: 50%;
  right: 14px;
}