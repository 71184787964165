.upload-document-page-container .page-footer {
  padding: 16px 40px;
  display: flex;
  justify-content: space-between;
}

.upload-document-page-container {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.upload-document-page-container .modal-content {
  overflow-y: auto;
}

.upload-document-page-container {
  .beta-label {
    background-color: #f9b234;
    font-weight: 600;
    font-size: 0.8rem;
    padding: 5px 10px;
    border-radius: 3px;
    color: #07032b;
    margin-left: 10px;
  }

  .radio-label-wrapper {
    font-size: 0.8rem;
  }
}

.add-faq-container {
  .info-block {
    margin: 0;
  }
}

.preview {
  .page-footer {
    justify-content: end;
  }
}

@media (min-width: 768px) {
  .preview {
    .extract-quiz-info {
      display: inline-block;
      vertical-align: top;
      width: unset;
    }
    .edit-extract-quiz {
      .extract-quiz-questions {
        width: calc(100% - 300px);
        padding-left: 1.25rem;
      }
    }
    .quiz-info-extract,
    .table-preview-content {
      height: calc(100vh - 400px);
      min-width: 500px;
      min-height: 350px;
    }
  }

  .pdf-viewer-wrapper {
    max-height: calc(100vh - 310px);
  }

  .create-quiz-upload-doc {
    .pdf-viewer-wrapper {
      max-height: 100%;
    }
  }
}
