.center {
  text-align: center;
}

.periodic-table-wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: 1700px;
}

.periodic-table-wrapper #table {
  display: grid;
  grid-template-columns: repeat(17, auto) 1fr;
}

/* Every element box  */
.periodic-table-wrapper .element {
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 2px;
  border: 1px solid #333;
  border-collapse: collapse;
  overflow: hidden;
}

.periodic-table-wrapper .number {
  float: left;
  font-size: 6px;
  position: absolute;
}

.periodic-table-wrapper .symbol {
  font-weight: bold;
  text-align: center;
  margin-top: 5px;
  font-size: 12px;
}

.periodic-table-wrapper .element-name {
  font-size: 4px;
  text-align: center;
}

.periodic-table-wrapper #element-box .symbol {
  font-size: 12px;
}

.periodic-table-wrapper #element-box .number {
  font-size: 6px;
}

.periodic-table-wrapper #element-box .element-name {
  font-size: 8px;
}

.periodic-table-wrapper #element-box {
  grid-column: 3/5;
  grid-row: 2/4;
  margin: 0;
  padding: 0.2vw;
  overflow: hidden;
}

/* Information Table  */
.periodic-table-wrapper #information {
  grid-column: 5/13;
  grid-row: 2/4;
  padding: 0.5vw;
  padding-top: 0;
  font-size: 1vw;
  max-height: 10vw;
  overflow-y: scroll;
}

.periodic-table-wrapper #information h1 {
  margin: 0 0 0.5vw 0;
}

.periodic-table-wrapper .big_title {
  display: inline-block;
}

.periodic-table-wrapper .cat_name {
  border-radius: 3px;
  padding: 0 5px;
  margin-left: 5px;
  top: -0.7vw;
  position: relative;
}

.periodic-table-wrapper .close-button {
  float: right;
  cursor: pointer;
  color: #ccc;
}

.periodic-table-wrapper .close-button:hover {
  color: #333;
}

.periodic-table-wrapper .appearance {
  margin-bottom: 0.5vw;
}

.periodic-table-wrapper .atom_info {
  margin-bottom: 0.5vw;
  color: #998100;
}

/* Helium to the top right  */
.element-2 {
  grid-column-start: -2;
}

/* Boron & Alumunium to the right  */
.element-5,
.element-13 {
  grid-column-start: 13;
}

/* Lanthinide & Actinoid split  */
.element-58,
.element-90 {
  grid-column-start: 3;
}

/* I DID NOT KNOW THERE WAS 119th ONE! */

.element-119 {
  grid-column-start: -2;
}

/* Categories and Groups */

/* Noble Gas  */
.periodic-table-wrapper .noble {
  background: #f3d2e8;
}

/* Alkali Metal  */
.alkali {
  background: #f88379;
}

/* Alkaline Earth Metal  */
.alkaline {
  background: #ffc3a0;
}

/* Transition Metal  */
.periodic-table-wrapper .transition {
  background: #e9ff08;
}

/* Polyatomic Non Metal  */
.polyatomic {
  background: #a3d2d5;
}

/* Diatomic Non Metal  */
.diatomic {
  background: #a3d5bf;
}

/* Post Transition Metal  */
.periodic-table-wrapper .post-transition {
  background: #8eb1e7;
}

/* Metalloid  */
.metalloid {
  background: #5ed89c;
}

/* Lanthanide  */
.lanthanide {
  background: #c9f;
  /* Put a space to seperate the split table  */
  /* margin-top: 2.5vw; */
}

/* Actinide  */
.periodic-table-wrapper .actinide {
  background: #ad87bd;
}

/* The Unknown Element  */
.periodic-table-wrapper .unknown {
  background: #cff;
}

/* Disable the top space for the first lathenide which sits inside the table  */
.periodic-table-wrapper .element-57 {
  margin: 0;
}

/* When in the hover state  */
.periodic-table-wrapper .active {
  background: #fff;
  /* border-color: yellow; */
}
