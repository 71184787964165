.quiz-table-wrapper {
    display: flex;
    align-items: flex-start;
  }
  
  .quiz-table {
    width: 100%;
  }
  
  .quiz-table,
  .quiz-table-row-cell,
  .quiz-table-header-cell {
    border: 1px solid #bec5d9;
  }
  
  .quiz-table-header-cell,
  .quiz-table-header-cell .custom-input {
    background-color: #eceff7;
    font-weight: bold;
  }
  
  .quiz-table-row-cell .custom-input,
  .quiz-table-header-cell .custom-input {
    resize: none;
    font-size: 0.8rem;
  }
  
  .quiz-table-row-cell .custom-input,
  .quiz-table-header-cell .custom-input {
    min-width: 0 !important;
    border: none;
  }
  
  .quiz-table-header-cell {
    position: relative;
  }
  
  .quiz-table-wrapper .column-remove-btn {
    position: absolute;
    right: 0;
    top: 8px;
    display: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0 5px;
  }
  
  .quiz-table-wrapper .quiz-table-row-toolbar {
    position: absolute;
    right: -56px;
    top: 12px;
    min-width: 50px;
  }
  
  .quiz-table-wrapper .quiz-table-row-toolbar .row-add-btn {
    background-color: transparent;
    cursor: pointer;
    border: none;
    font-size: 0.8rem;
  }
  
  .quiz-table-wrapper .row-remove-btn {
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0 5px;
  }
  
  .quiz-table-wrapper .column-add-btn {
    margin-left: 5px;
  }
  
  .quiz-table-header-cell:hover .column-remove-btn {
    display: block;
  }
  
  .quiz-table-row {
    position: relative;
  }
  
  .quiz-table-wrapper .icon-delete {
    color: #c34343;
    font-size: 0.75rem;
  }
  
  .quiz-table-wrapper .custom-popover *,
  .quiz-table-wrapper .custom-popover {
    /* position: unset !important; */
    /* overflow: unset !important; */
    word-break: initial;
  }
  
  .quiz-table-wrapper .custom-popover .popover-box {
    background-color: rgba(30, 76, 136, 0.9);
    border-radius: 3px;
    padding: 16px;
    max-width: 450px;
    min-width: 100%;
    color: #fff;
    font-weight: bold;
    font-size: 0.7rem;
    min-width: 200px;
  }
  
  .quiz-table-wrapper .custom-popover .popover-box .textinput-label {
    color: #ffffff;
  }
  
  .quiz-table-popover-arrow {
    color: rgba(30, 76, 136, 0.7);
  }
  
  .quiz-table-row-cell {
    position: relative;
  }
  
  .quiz-table-wrapper .quiz-table-cell-toolbar {
    position: absolute;
    right: 10px;
    top: 8px;
    display: none;
  }
  
  .quiz-table-row-cell:hover .quiz-table-cell-toolbar {
    display: block;
  }
  
  .quiz-table-row-cell .cell-property-btn {
    background-color: transparent;
    border: none;
    font-size: 0.8rem;
    cursor: pointer;
    color: #108e68;
  }
  
  .remove-up-down-arrow {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .quiz-table-master-container input:disabled {
    background-color: #ccc;
  }

  .quiz-table-master-container input:disabled::placeholder {
    color: #777;
  }