@font-face {
    font-family: 'Mulish';
    src: local('Mulish'), url('./fonts/Mulish-Medium.eot');
    src: local('Mulish'), url('./fonts/Mulish-Medium.eot?#iefix') format('embedded-opentype'),
        local('Mulish'), url('./fonts/Mulish-Medium.ttf') format('truetype'),
        local('Mulish'), url('./fonts/Mulish-Medium.svg#Mulish-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: local('Mulish'), url('./fonts/Mulish-Bold.eot');
    src: local('Mulish'), url('./fonts/Mulish-Bold.eot?#iefix') format('embedded-opentype'),
        local('Mulish'), url('./fonts/Mulish-Bold.ttf') format('truetype'),
        local('Mulish'), url('./fonts/Mulish-Bold.svg#Mulish-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: local('Mulish'), url('./fonts/Mulish-Light.eot');
    src: local('Mulish'), url('./fonts/Mulish-Light.eot?#iefix') format('embedded-opentype'),
        local('Mulish'), url('./fonts/Mulish-Light.ttf') format('truetype'),
        local('Mulish'), url('./fonts/Mulish-Light.svg#Mulish-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: local('Mulish'), url('./fonts/Mulish-ExtraBold.eot');
    src: local('Mulish'), url('./fonts/Mulish-ExtraBold.eot?#iefix') format('embedded-opentype'),
        local('Mulish'), url('./fonts/Mulish-ExtraBold.ttf') format('truetype'),
        local('Mulish'), url('./fonts/Mulish-ExtraBold.svg#Mulish-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: local('Mulish'), url('./fonts/Mulish-Regular.eot');
    src: local('Mulish'), url('./fonts/Mulish-Regular.eot?#iefix') format('embedded-opentype'),
        local('Mulish'), url('./fonts/Mulish-Regular.ttf') format('truetype'),
        local('Mulish'), url('./fonts/Mulish-Regular.svg#Mulish-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: local('Mulish'), url('./fonts/Mulish-SemiBold.eot');
    src: local('Mulish'), url('./fonts/Mulish-SemiBold.eot?#iefix') format('embedded-opentype'),
        local('Mulish'), url('./fonts/Mulish-SemiBold.ttf') format('truetype'),
        local('Mulish'), url('./fonts/Mulish-SemiBold.svg#Mulish-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
