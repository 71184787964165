@import "../../../src/variables.scss";

.dashborad-wraper {
  padding: 0 0 32px;
  nav.nav {
    background-color: $bg-grey;
    @include radius(10px);
    padding: 0px 20px;
    a {
      font-size: 0.875rem;
      color: $clr-primary;
      text-decoration: none;
      padding: 15px 20px;
      &.active,
      &:hover {
        font-weight: bold;
      }
    }
    @media (max-width: 767px) {
      padding: 0px 10px;
      a {
        padding: 10px;
      }
    }
  }
  .progress {
    width: 100%;
    @include radius(10px);
    height: 12px;
    background-color: $clr-grey;
    margin: 0 5px 0 20px;
    .progress-bar {
      background: linear-gradient(
        -90deg,
        rgba(22, 242, 228, 1) 0%,
        rgba(0, 172, 255, 1) 63%,
        rgba(60, 9, 230, 1) 100%
      );
      @include radius(10px);
    }
    + span {
      min-width: 40px;
      text-align: right;
    }
  }
}

.breadcrumb {
  background-color: $bg-grey;
  @include radius(10px);
  padding: 14px 40px;
  margin-bottom: 0;
  a {
    font-size: 0.875rem;
    color: $clr-primary;
  }
  .active {
    color: $clr-primary;
    font-weight: bold;
  }
  .breadcrumb-item {
    + .breadcrumb-item {
      &:before {
        content: url("../../assets/images/chevron-down-blk.svg");
        padding: 0;
        margin-right: 10px;
        transform: rotate(-90deg);
      }
    }
  }
}
.welcome-wrap {
  padding: 35px 0 0 32px;
  background: url("../../assets/images/welcomeBg.svg") no-repeat bottom right;
  @media (max-width: 991px) {
    background-size: contain;
  }
  article {
    padding-left: 35px;
  }
  h1 {
    @media (min-width: 992px) {
      font-size: 2rem;
    }
  }
  @media (max-width: 767px) {
    padding: 25px 0 0 0px;
    article {
      padding-left: 10px;
    }
  }
  &.welcome-subtext {
    padding-left: 0;
    background-image: none;
    > h2 {
      position: relative;
      display: inline-block;
      &:after {
        content: url("../../assets/images/welcomeSubBg.svg");
        position: absolute;
        left: 100%;
        top: -23px;
        margin-left: -30px;
      }
    }
  }
}
.filters {
  border: 1px solid $border-grey;
  @include radius(10px);
  padding: 14px 32px;
  @media (max-width: 991px) {
    padding: 10px 15px;
  }
  @media (max-width: 575px) {
    > h3 {
      width: 100%;
      margin-bottom: 10px !important;
    }
  }
}
.graph-overview {
  .graph-box {
    // width: calc(88% / 5);
    // margin-right: 3%;
    // @media(max-width:991px){
    //     width: calc(91% / 3);
    // }
    // @media(max-width:768px){
    //     width: calc(94% / 2);
    // }
    // @media(max-width:640px){
    //     width:100%;
    //     margin-right: 0;
    // }
    // &:last-child{
    //     margin-right:0;
    // }
    h3 {
      font-size: 12px;
      color: rgba(7, 3, 43, 0.5);
    }
    h2 {
      font-size: 24px;
    }
  }
  @media (min-width: 992px) {
    > .col-lg-2 {
      flex: 0 0 20%;
      max-width: 20%;
    }
    &.session-3col {
      > .col-lg-2 {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
    }
  }
  &.row {
    margin-right: -20px;
    margin-left: -20px;
    [class*="col-"] {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}
.graph-box {
  @include shadow();
  @include radius(10px);
  background-color: $bg-white;
  padding: 20px;
  margin-bottom: 40px;
}
.growth-percent {
  font-size: 11px;
  font-weight: bold;
  padding-left: 13px;
  &.growth-up {
    background: url("../../assets/images/growthUp.svg") no-repeat center left;
    color: #3cc480;
  }
  &.growth-down {
    background: url("../../assets/images/growthDown.svg") no-repeat center left;
    color: #ff4141;
  }
}

.select-target.select-sort {
  @include radius(3px);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);
  @media (max-width: 480px) {
    margin-bottom: 6px;
  }
  > div {
    height: auto;
    min-height: 32px;
    min-width: 150px;
  }
  .select-target-prefix__indicators {
    margin-right: 0px;
    > .select-target-prefix__indicator {
      padding: 0 8px;
      color: $clr-primary;
    }
  }
  .select-target-prefix__menu {
    // react-select__menu
    border-radius: 3px;
    > div > div {
      font-size: 14px;
      padding: 9px 12px;
    }
  }
  + .select-sort {
    margin-left: 1rem;
  }
}
.overall-rate {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  background-color: $bg-primary;
  display: inline-block;
  color: $clr-white;
  min-width: 72px;
  padding: 9px 5px;
  @include radius(20px);
  margin-bottom: 30px;
  &:after {
    content: "";
    border: 10px solid transparent;
    border-top-color: $border-primary;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
  }
  + figure {
    > img {
      width: 100px;
    }
  }
}
.rate-smily {
  width: 50px;
  height: 36px;
  @include radius(50%);
}

.more-filters {
  border: 1px solid $border-primary;
  color: $clr-txt-primary;
  font-size: 14px;
  font-weight: 600;
  @include radius(3px);
}

.sidebar-filter {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
  > aside {
    background-color: $clr-white;
    box-shadow: 0px 0px 16px 0px rgba(185, 182, 224, 0.16);
    width: 550px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -550px;
    z-index: 4;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
    flex-direction: column;
    > div {
      width: 100%;
      &.row {
        height: calc(100% - 170px);
        aside {
          background-color: #f5f6fa;
          height: 100%;
          a,
          span {
            font-size: 14px;
            color: $clr-primary;
            display: block;
            padding: 16px 16px;
            text-decoration: none;
            border-bottom: 1px solid #cdd5ef;
            &.active {
              background-color: $clr-white;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(205, 213, 239, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
  }
  &.open-filter {
    opacity: 1;
    visibility: visible;
    > aside,
    &:after {
      right: 0;
      opacity: 1;
      visibility: visible;
    }
  }
}
.select-usergroup {
  @include reactdropdown();
}

hr {
  border-color: $clr-border;
}
.ques-error {
  border: 1px solid $ques-error-border;
}
.moreLessLegends {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 32px 10px;
  > span {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    margin: 0 5px;
    &:first-child {
      background-color: #eceff7;
      margin-left: 15px;
    }
    &:nth-child(2) {
      background-color: #ceff80;
    }
    &:nth-child(3) {
      background-color: #b2ea57;
    }
    &:nth-child(4) {
      background-color: #94e513;
    }
    &:last-child {
      background-color: #7dcb00;
      margin-right: 15px;
    }
  }
}
.searchby-users-sesssion {
  max-height: 520px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
}
.iframe-wraper {
  height: calc(100vh - 210px);
}

.session-wrapper {
  .rdt_TableHeadRow {
    height: 70px;
  }
}
.all-knowledge-search {
  .session-active {
    color: #00864d;
  }
}

.teachers-toolkit-box {
  display: flex;
  padding: 1.75rem 1.5rem !important;
  cursor: pointer;
  height: 100%;
  border: 0.975352px solid #cdd5ef;
  box-shadow: 0px 3.90141px 3.90141px #b9b6e0;
  border-radius: 7.80282px;
  align-items: center;
  min-height: 151px;
  position: relative;
  .start-here {
    position: absolute;
    top:0;
    right: 0;
    background: #FF009E;
    border-radius: 0px 8px 0px 8px;
    padding: 6px;
    color: white;
    font-size: 12px;
  }
  .start-video {
    padding-top: 4px;
    color: #0923e6;
  }
  .inner-box {
    width: 60px;
    > img {
      width: 48px;
      height: 56px;
    }
  }
  .inner-box-crsl {
    width: 60px;
    > img {
      width: 40px;
      height: 40px;
    }
  }
  .inner-text,
  .inner-text-2 {
    padding-left: 8px;
    width: calc(100% - 60px);
    // margin-top: 1rem;
    > h2 {
      font-size: 18px;
      color: #0923e6;
    }

    > h1 {
      font-size: 1rem;
      color: #0923e6;
    }

    .description {
      font-size: 0.875rem;
      color: #6b7085;
      .counter {
        color: #ff009e;
      }
    }
  }
}

.process-documents {
  text-align: center;
}

.selected-questions-wrapper {
  border-radius: 8px;
  background: #f0f4ff;
  box-shadow: 0px 4px 4px 0px #b9b6e0;
  padding: 24px;

  &.recommened {
    background: white;
    &.group {
      box-shadow: 0px 4px 4px 0px #b9b6e0;
    }
  }
}

.outcome-list {
  height: calc(100vh - 500px);
  overflow: auto;
}

.toolkit-recommened {
  .outcome-list {
    height: calc(100vh - 542px);
    overflow: auto;
  }
}

.toolkit-learning-outcome {
  .outcome-list {
    height: calc(100vh - 450px);
    overflow: auto;
  }
}

.outcome-list.emty {
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
}

.outcome-item {
  border-radius: 4px;
  background: #fefaeb;
}

.toolkit-guided-question-bank {
  .card-header {
    position: relative;
    font-size: 1rem;
    font-weight: 600;
    padding: 0;
    @include radius(8px !important);
    border: 0;
    background-color: $bg-white;
    cursor: pointer;

    // &:after {
    //   content: url("assets/New-images/chevron-right.svg");
    //   position: absolute;
    //   right: 20px;
    //   top: 50%;
    //   transform: translateY(-50%);
    // }

    span {
      display: block;
      padding: 1.25rem 2.5rem 1.25rem 2.5rem !important;
      position: relative;
      z-index: 1;
      justify-content: space-between;
      align-items: center;

      &.is_suggested {
        border-left: 3px solid rgba(255, 0, 158, 0.7);
      }
    }

    img {
      position: absolute;
    }

    input[type="checkbox"] {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 1rem;
      transform: translateY(-50%);
    }

    .k-breadcrumb {
      text-transform: none;
      font-size: 12px;
      margin-bottom: 2px;
    }
  }

  .card {
    border: 1px solid $clr-border !important;
    @include radius(8px !important);
    margin-bottom: 1rem;
    box-shadow: 0px 4px 4px $clr-shadow;

    .card-body {
      background-color: $clr-grey;
      padding: 1.25rem;
      max-height: initial;
      overflow-y: initial;
      margin-right: 0;
    }
  }

  .active {
    border: 0;

    .card-header {
      &:after {
        transform: rotate(90deg);
        top: 16px;
        right: 22px;
      }
    }
  }

  .doc-select-dropdown {
    .select-target-prefix__placeholder {
      color: $clr-txt-primary;
    }
  }
}

.toolkit-progress-bar {
  border-radius: 1rem;
  background-color: #f0f4ff;
  height: 1.5rem;

  .progress-bar {
    background-color: #0923e6;
  }
}

.hide {
  display: none !important;
}

.smartQuizAddMoreList {
  .scroll-wrap,
  .pdf-viewer-wrapper {
    max-height: 440px;
  }
}

.next-btn-disable {
  width: 93px;
  .next-spinner {
    border: 2px solid #6c757d;
    border-right-color: transparent;
  }
}

.toolkit-select-module-modal {
  .modal-content {
    border-radius: 16px;
  }

  .modal-content.scroll-wrap {
    max-height: 650px;
  }

  .knowledge-box {
    height: 340px;

    .modules-list {
      ul {
        li.not-active {
          &:hover,
          &:focus,
          &.active {
            background-color: $bg-grey-hover;
            color: $clr-txt-primary !important;
          }
          a {
            &:hover,
            &:focus,
            &.active {
              font-weight: 700;
              color: $clr-txt-primary !important;
            }
          }
          &.not-hover {
            background-color: $bg-white;
          }
        }
        margin-bottom: 0px;
      }
    }
  }
  .knowledge-box.build-knowbox.bg-white {
    box-shadow: 0px 4px 4px #b9b6e0;
  }
}

.toolkit-drag-drop {
  .custom-file-wrap {
    background-color: #eceff7;
  }
}

.link-questions {
  .modal-dialog {
    max-width: 640px;
  }
}

.try-sample-btn {
  .btn-outline-primary {
    &:disabled {
      background-color: #ECEFF7;
      border-color: #CDD5EF;
      color: #9398AB;
      opacity: 1;
    }
  }
}

.integration-sources {
  .select-course {
    .select-target.select-blue {
      min-width: 240px;
      border-radius: 4px;
      
      .css-1uccc91-singleValue {
        color: $clr-txt-primary !important;
      }
  
      .select-target-prefix-summarise__menu{
        z-index: 2;
      }
    
      .select-target-prefix-summarise__option{
        &.select-target-prefix-summarise__option--is-selected {
          background-color: $clr-txt-primary;
        }
      }
    }

    .web-links {
      margin-bottom: 0px;
      min-width: inherit;
      width: 100%;
  
      > input {
        background-image: url("../../assets/images/search-blk.svg");
        background-repeat: no-repeat;
        background-position: 11px 50%;
        padding-left: 40px;
      }
    }

    .set-course-id > input {
      border: none;
    }
  }

  .accordion-viewby .card-header:after{
    display: none !important;
  }

  .empty-content-list{
    height: 400px;

    .noodle-img {
      max-width: 158px !important;
    }
  }

}


