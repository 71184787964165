.pagination-wraper {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  z-index: 2;
  background: #ffffff;
  width: 100%;
  bottom: -3px;
  left: 0;
  padding: 20px;
  select {
    border: 0;
    color: #0923e6;
    font-weight: 600;
    outline: none;
    margin: 0 3px;
  }
  nav {
    span {
      color: rgba(7, 3, 43, 0.5);
    }
  }
  .btn {
    &:hover,
    &.active {
      svg {
        path,
        line {
          stroke: #0923e6;
        }
      }
    }
  }
}
.table-slide{
  padding: 0;
  border:1px solid #CDD5EF;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  &.tbl-R{
    right: 15px;
  }
  &.active{
    border-color: #0923e6;
    svg {
      path {
        fill: #0923e6;
      }
    }
  }
  + .suggested-option{
    margin-left: 45px;
    + nav{
      margin:0 auto;
    }
  }
  + nav{
    margin:0 auto;
  }
}
.knowledge-box-container .pagination-wraper {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  z-index: 2;
  background: #ffffff;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 20px;
}