@import '../../../../src/variables.scss';
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

/* mdl-demo is a custom classname to increases the specificity of our styles. It can be anything. 
 * The idea is that it is easy to extend/override builtin styles with very little effort.
 */

.mdl-demo .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.mdl-demo .toggle {
  font: normal normal normal 18px/1 'Material Icons';
  color: #07032b;
  white-space: pre;
  margin-right: 4px;
}

.mdl-demo .toggle.collapsed::after {
  cursor: pointer;
  content: '\E5CF';
  vertical-align: middle;
}

.mdl-demo .toggle.expanded::after {
  cursor: pointer;
  content: '\E5CE';
  vertical-align: middle;
}

/* checkbox styles */
.mdl-demo .checkbox-item {
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 0.75rem;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  vertical-align: middle;
}

.mdl-demo .checkbox-item:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 1px solid #cdd5ef;
  border-radius: 3px;
  text-align: center;
}
.mdl-demo .checkbox-item:checked:before {
  content: url('../../../assets/images/check-green.svg');
  border-color: #11864d;
}
.mdl-demo .node {
  background-color: transparent;
}
.table-wraper {
  scroll-behavior: smooth;
  .btn-group {
    .btn {
      padding: 0 5px;
      font-size: 14px;
      font-weight: 600;
      margin-right: 10px;
      white-space:nowrap;
      &.btn-edit {
        color: #11864d;
      }
      &.btn-delete {
        color: #b00020;
      }
      &.btn-suggest {
        color: #0923e6;
      }
    }
  }
  .knowledge-tags {
    padding-bottom: 0;
    > div {
      padding: 4px 10px;
      margin: 5px 8px 0px 0;
    }
  }
  .rdt_TableBody::-webkit-scrollbar {
    width:1px;
  }
  
}

.react-select-container,
.react-select-category-container {
  width: 100%;
  max-width: 320px;
  border: 1px solid #cdd5ef;
  height: 47px;
  border-radius: 10px;
  padding: 0 14px 0 7px;
  > div {
    border-width: 0px !important;
    box-shadow: none !important;
    border-radius: 10px;
    min-height: 45px;
    cursor: pointer;
  }
  .react-select__menu {
    border-radius: 5px;
    box-shadow: 0px 0px 12px 0px rgba(154, 165, 255, 0.32) !important;
    overflow: hidden;
    > div {
      margin-right: -17px;
    }
  }
  .dropdown-para {
    padding-left: 13px;
    color: #07032b;
    font-size: 14px;
    margin-bottom: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
  }
  .child-para {
    &:hover {
      color: #fff;
      background-color: #0923e6;
      .dropdown-para {
        color: #fff;
      }
    }
    &.selected {
      color: #fff;
      background-color: #0923e6;
      .dropdown-para {
        color: #fff;
      }
    }
  }
}
// .react-select-category-container{
//   height:auto;
//   .react-select__control{
//     min-height:auto;
//   }
// }

.all-knowledge-table, .uploaded-doc-table {
  transition: all 0.5s;
  .form-check-label {
    border: 0;
    padding: 0;
    &:before {
      line-height: 22px;
    }
  }
  .table-wraper{
    .rdt_TableRow{
      min-height: auto;
    }
    &.border-0{
      .rdt_TableBody{
        border-width:0;
      }
    }
  }
}

.knowledge-tags {
  padding-bottom: 10px;
  > div {
    background-color: #0923E6;
    border: 1px solid #0923E6;
    border-radius: 3px;
    font-size: 12px;
    color: #fff;
    padding: 0px 10px;
    margin: 5px 8px 5px 0;
    display: flex;
    align-items: center;
    .btn {
      padding:  12px 0px 6px 6px;
      img {
        width: 10px;
        margin-top: -3px;
      }
    }
  }
  > .btn-outline-primary {
    border-radius: 3px;
    padding: 0 10px;
    height: 28px;
  }
}
.delete-rows {
  background-color: $sec-bg-grey;
  border-radius: 3px 3px 0 0;
  padding: 5px 5px;
  margin-bottom: -2px;
  animation: fadein 350ms ease-in-out;
  .btn-delete {
    color: #b00020;
    font-weight: 600;
    font-size: 14px;
  }
}
@keyframes fadein {
  0% {
    margin-bottom: -45px;
  }
  100% {
    margin-bottom: -2px;
  }
}
@mixin text-elipse() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.expand-wraper {
  p {
    @include text-elipse();
  }
  h3 {
    font-size: 12px;
  }
  ol {
    padding-left: 15px;
    p {
      margin-bottom: 2px;
      white-space: normal;
      max-width: 70%;
    }
  }
  .posible-ques {
    padding: 16px 22px 0;
    background-color: #fbfcff;
  }
  .parent-ques {
    padding: 0px 22px;
  }
}
.popover-tags-component {
  background-color: $popover-bg-notransparent;
  padding: 5px 12px;
  // box-shadow: 0px 0px 4px 0px $dropdown-shadow;
  // border:1px solid $clr-border;
  margin-left: 3px !important;

  &:before {
    content: '';
    border: 7px solid transparent;
    border-right-color: $border-grey;
    position: absolute;
    right: 100%;
    top: 50%;
    margin-top: -7px;
  }
  .knowledge-tags {
    padding-bottom: 0;
    > div {
      padding: 2px 10px;
      background: $clr-white;
      border: none;
      color: $clr-black;
    }
  }
}
.view-suggest {
  padding: 16px 22px;
  color: $clr-primary;
  font-size:0.875rem;
  border-bottom:1px solid $clr-border;
  word-break: break-all;
  .btn-delete{
    color: $clr-error !important;
  }
}
