.upload-document-page-container .page-footer {
    padding: 16px 40px;
    display: flex;
    justify-content: space-between;
  }
  
  .upload-document-page-container {
    height: 100%;
    display: flex;
    flex-flow: column;
  }
  
  .upload-document-page-container .modal-content {
    overflow-y: auto;
  }
  
  .upload-document-page-container{
    .beta-label {
      background-color: #f9b234;
      font-weight: 600;
      font-size: 0.8rem;
      padding: 5px 10px;
      border-radius: 3px;
      color: #07032b;
      margin-left: 10px;
    }
    .radio-label-wrapper {
      font-size: .8rem;
    }
  }
  .add-faq-container {
    .info-block {
      margin: 15px 0px 0px 25px;
    }
  }
