.groups-tree {
  max-height: calc(100vh - 350px);
  overflow-y: auto;
  .smooth-dnd-draggable-wrapper {
    padding-right: 0px;
  }

  .tree-p {
    padding: 1rem 0;
  }

  .tree-p {
    padding: 1rem;
  }
  .tree-p-1 {
    padding-left: 2rem;
  }

  .tree-p-2 {
    padding-left: 3rem;
  }

  .tree-p-3 {
    padding-left: 4rem;
  }

  .tree-p-4 {
    padding-left: 5rem;
  }

  .group-p-2 {
    padding-left: 2rem;
  }

  .group-p-3 {
    padding-left: 3rem;
  }

  .group-p-4 {
    padding-left: 4rem;
  }

  .smooth-dnd-container {
    min-height: 0px;
  }
}

.knowledge-box.build-knowbox.bg-white {
  box-shadow: none;
}

.groups-item {
  border: 1px solid #cdd5ef;
  &.group-selected {
    background: #e6edff;
  }
}

.flex-1 {
  flex: 1;
}

.input-edit-group {
  position: relative;
  margin-right: 10px;
  input {
    border-radius: 4px;
    border: 1px solid #cdd5ef;
    padding: 5px;
  }
}

.edit-actions {
  position: absolute;
  right: 5px;
}

.add-new-group {
  // border: 1px solid #cdd5ef;
  border-radius: 8px 0 8px 8px;
  background: #e6edff;
  padding: 16px;
  .new-group-btn {
    position: absolute;
    right: 0;
    bottom: 100%;
    padding: 8px;
    border-radius: 8px 8px 0 0;
    background-color: #e6edff;
  }

  input {
    height: 33px;
  }
}

.add-new-group-row {
  padding: 13px 0 13px 0;
}

.manage-knowledge-header {
  border-radius: 8px 8px 0px 0px;
}

.manage-knowledge-bottom {
  border-radius: 0px 0px 8px 8px;
}
