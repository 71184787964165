@import "../../variables.scss";


.discussion {
  input.form-control.edit {
    background-image: url("../../assets/images/search-blk.svg");
    background-repeat: no-repeat;
    background-position: 11px 50%;
    padding: 4px;
    padding-left: 40px;
    max-width: 300px;
  }
  .author-ava {
    border: 1px solid #eceff5;
    border-radius: 50%;
    height: 40px;
    position: relative;
    width: 40px;
    object-fit: contain;
  }

  .chev.expanded {
    transform: rotate(90deg);
  }

  .rdt_TableRow {
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px #b9b6e0;
    margin-bottom: 5px;
  }

  .post-item {
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px #b9b6e0;
    margin-bottom: 5px;
  }

  .creating-bg {
    background-color: rgba(14, 7, 72, 0.3);
    background-color: rgba(14, 7, 72, 0.3);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }

  .content-page {
    height: 100%;
    background: white;
    max-width: 750px;
    width: 95%;
    right: 0;
    position: absolute;
    padding: 32px;

    input.form-control {
      background-color: #ffe6f1;
      position: relative;
      padding-right: 50px;
    }
    .clear-input {
      position: absolute;
      right: 10px;
      bottom: 17px;
    }

    .select-target-prefix__multi-value {
      background: #e6edff;
      border-radius: 8px;
    }

    .select-target-prefix__placeholder {
      color: #0923e6;
    }

    .bottom-btns {
      float: right;
    }
  }

  .select-sort.select-target.select-blue {
    width: 200px;
    border-color: $border-primary;
    border-radius: 4px;
    .select-target-prefix__placeholder {
      color: $clr-txt-primary;
    }
    .select-target-prefix__control {
      background-color: $bg-white;
    }
    .select-target-prefix__value-container,
    .select-target-prefix__single-value {
      color: $clr-txt-primary;
      font-weight: 400;
    }
  }
  .table-wraper-trending-questions {
    border: none;
  }
  .edit-learner-question {
    top: 0;
    right: 0;
    width: 100%;
  }
  .nav.nav-tabs {
    background: none;
    display: inline-flex;
  }
  .learner-question-tab {
    min-height: 600px;
  }
}

.action-button {
  padding: 0 10px;
  > img {
    width: 15px;
  }
  &.cross-btn {
    > img {
      width: 10px;
    } 
  }
}

.chat-responses-tab {
  .learner-question-tab {
    .learners-filters {
      padding-top: 0px !important;
      padding-bottom: 1rem !important;
    }
  }
}
