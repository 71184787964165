.checkradio-container .custom-checkbox {
  width: 16px;
  height: 16px;
  -webkit-appearance: none;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin: 0 16px 0 0;
  cursor: pointer;
  vertical-align: sub;
}

.checkradio-container .custom-checkbox:after {
  content: "\e925";
  font-family: "icomoon";
  color: #e5e5e0;
  font-size: 20px;
}

.checkradio-container .custom-checkbox:checked:after {
  content: "\e925";
  font-family: "icomoon";
  color: #1e4c88;
  color: var(--secondary-color);
  font-size: 20px;
}

.checkradio-container .checkbox-label {
  font-size: 0.9rem;
}

.checkradio-container .custom-checkbox:focus {
  outline: none;
}
