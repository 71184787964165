.sun-editor-editable {
  font-size: 0.9rem;
  font-family: "Muli";
}

.sun-editor {
  border-color: #bec5d9;
  border-radius: 10px;
  /* overflow: hidden; */
  padding: 0 10px;
}

.sun-editor .se-toolbar {
  background-color: #fff;
  outline: none;
  border-bottom: 1px solid #eceff7;
  z-index: 2;
}

.sun-editor .se-btn-module-border {
  border: none;
  margin-right: 8px;
}

.sun-editor .se-resizing-bar.se-resizing-none {
  /* display: none; */
  background-color: #fff;
  padding: 5px 0;
  border-top: 1px solid #eceff7;
}

.sun-editor .se-resizing-bar .se-char-counter-wrapper {
  font-size: 0.7rem;
  font-family: "Muli";
}

.sun-editor .editor-icons::before {
  font-size: 1.1rem;
}

.sun-editor .se-wrapper .se-wrapper-inner {
  min-height: 110px;
  overflow-wrap: anywhere;
}

.sun-editor .icon-quote::before,
.sun-editor .icon-strike::before {
  font-size: 1rem;
}

.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form-footer
  .se-dialog-btn-check {
  width: 16px;
  height: 16px;
}

.sun-editor-editable * {
  font-size: 0.8rem;
}
