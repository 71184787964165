.integration {
  font-size: 1rem;
  .integration-item {
    box-shadow: 0px 4px 4px #B9B6E0;
    border-radius: 16px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .integration-item img {
    max-height: 80px;
  }
}
.integration-modal-body {
  display: grid;
  gap: 1rem
}
.configure-btn button {
  width: 100%;
  background: transparent !important;
  color: #0923E6;
}
.configure-btn button:hover {
  background: #0923E6 !important;
  color: #fff !important;
}
.configure-btn button:focus {
  background: #0923E6 !important;
  color: #fff !important;
}
.configure-btn {
  margin-top: 20px;
}
.integrationBox-heading h4 {
  font-size: 24px;
  font-weight: 800;
  color: #000866;
  line-height: 32px;
}
.integrationBox-connection p {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.success {
  color: #00864D;
}
.error {
  color: #D71E30;
}
