.textinput-label{
    font-size: .8rem;
    font-weight: bold;
    margin-bottom: 16px;
}
.custom-input{
    border: 1px solid #bec5d9;
    border-radius: 3px;
    padding: 8px;
    font-size: .9rem;
    outline: none;
}
.clearfix {
    height: 32px;
}
.textinput-error{
    color: #c34343;
    font-size: 12px;
    margin-top: 5px;
    font-weight: 600;
}
.link-modal-action-bar{
    .custom-icon-label-button{
        font-size: .8rem;
        text-decoration: none;
    }
}